@media print {

  html,
  body {
    height: auto !important;
    margin: 0 !important;
    padding: 1 !important;
    background-color: transparent !important;
    font-family: 'Segoe UI', sans-serif !important;
  }

  @page {
    size: auto;
    margin: 15mm;
  }

  @font-face {
    font-family: 'Jameel Noori Nastaleeq Regular';
    src: url('./fonts/JameelNooriNastaleeqRegular.woff') format('woff');
  }

  #aadhaar {
    border: 2px solid black;
    background: linear-gradient(to left, black 2px, transparent 0);
    background-size: 25px 1px;
    width: 300px;
    font-weight: bold;
    letter-spacing: 16.8px;
    text-indent: 8px;
    text-transform: uppercase;
  }

  #dob {
    border: 2px solid black;
    background: linear-gradient(to left, black 2px, transparent 0);
    background-size: 25px 1px;
    width: 200px;
    font-weight: bold;
    letter-spacing: 16.5px;
    text-indent: 8px;
    text-transform: uppercase;
  }

  #saral_id {
    border: 2px solid black;
    background: linear-gradient(to left, black 2px, transparent 0);
    background-size: 25px 1px;
    width: 480px;
    font-weight: bold;
    letter-spacing: 16.8px;
    text-indent: 7px;
    text-transform: uppercase;
  }

  #watermark {
    color: steelblue;
    font-size: 120px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(-45deg);
    opacity: 0.5
  }

  .tableTC td {
    font-size: 14.7px !important;
    text-decoration: solid;
    border: 1px solid black;
    padding: 2px;
  }

  .dataTC {
    font-weight: bold;
    padding: 1.5px;
  }

  .tableTC {
    border: 1px solid black
  }

  .footerTC {
    padding: 5px;
  }

  .footerTextTC {
    text-align: center;
    padding-bottom: 10px;
  }

  .signsTC {
    display: flex;
    justify-content: space-between;
    padding-bottom: 50px;
  }

  /* Template 2 - Start */

  .tableTemplate2 td {
    font-size: 14px !important;
    text-decoration: solid;
    border: 1px solid black;
    padding: 2px;
  }

  .headerTemplate2 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px;
  }

  .logoTemplate2 img {
    width: 100px;
  }

  .organizationTemplate2 {
    padding-bottom: 40px;
    text-align: center;
  }

  .schoolNameTemplate2 {
    font-size: 20px;
    font-weight: bold;
  }

  .foundation-nameTemplate2,
  .addressTemplate2 {
    font-size: 12px;
    text-align: center;
  }

  .certificateTemplate2 {
    font-size: 20px !important;
    color: #FFFFFF;
    background-color: rgb(99, 99, 99);
    padding: auto;
  }

  .ruleTemplate2 {
    font-size: 13px !important;
    font-weight: bold;
    margin-bottom: -45px !important;
  }

  /* Template 2 - End */

  .headerTC {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .headerTC .widthTC {
    width: 33% !important;
  }

  .foundationTC {
    font-size: 11px !important;
  }

  .schoolNameTC {
    font-size: 20px !important;
    font-weight: bold;
  }

  .logo-centerTC {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .affiliationTC {
    font-size: 13px !important;
    font-weight: bold;
    padding-top: 5px;
  }

  .logoTC img {
    width: 100%;
  }

  .logoTC {
    width: 100px;
  }

  .statusTC {
    font-size: 15 !important;
    font-weight: bold;
    color: red;
    padding: 3px;
  }

  .certificateTC {
    font-size: 20px !important;
    color: #FFFFFF;
    background-color: rgb(99, 99, 99);
  }

  .rule {
    font-size: 13px !important;
    font-weight: bold;
  }

  .urdu-right {
    text-align: right;
  }

  .foundation-urdu,
  .address-urdu {
    font-size: 15px !important;
  }

  .schoolName-urdu {
    font-size: 30px !important;
  }

  .urdu {
    font-family: 'Jameel Noori Nastaleeq' !important;
    font-weight: bold;
  }

  .headerDeclare {
    font-size: 11px !important;
    text-align: center;
    font-weight: bold;
  }

  .poweredTC {
    font-size: 11px !important;
    text-align: center;
  }

}