@import url('https://fonts.googleapis.com/css2?family=Acme&display=swap');

@media print {

  html,
  body {
    height: auto !important;
    margin: 0 !important;
    padding: 0 !important;
    /* overflow: hidden; */
    background-color: transparent !important;
  }

  @page {
    size: auto;
    margin: 15mm;
  }
}

.boarder {
  border: 15px groove #000000;
  height: 135mm;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-repeat: no-repeat;
  background-size: 100%;
}

.inner-bottom {
  background-color: #000000;
  height: 20px;
  color: #FFFFFF;
  font-size: 12px;
  display: flex;
  justify-content: center;
}

.link {
  color: #FFFFFF;
  background-color: transparent;
  text-decoration: none;
}

.main-body {
  height: 125mm;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 5px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
}

.logo {
  width: 100px;
}

.logo img {
  width: 100%;
}

.school {
  padding-top: 5px;
  margin-right: 50px;
}

.foundation {
  font-size: 13px;
}

.schoolName {
  font-size: 20px;
  padding-left: 0px;
  font-weight: bold;
}

.certificate {
  font-size: 20px;
  text-decoration: underline;
  font-family: 'Acme', sans-serif;
}

.main-content {
  padding-top: 10px;
  text-align: justify;
  font-family: 'Segoe UI', sans-serif;
    font-size: 17px !important;
}

.other {
  padding-top: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-family: 'Segoe UI', sans-serif;
    font-size: 17px !important;
}

.img {
  width: 90px;
  border: 1px solid #000
}

.img img {
  width: 100%;
}

.signs {
  display: flex;
  justify-content: space-between;
  padding-bottom: 50px;
  font-family: 'Segoe UI', sans-serif;
    font-size: 17px !important;
}