
@media print {
  html,
  body {
    height: auto !important;
    margin: 0 !important;
    padding: 0 !important;
    background-color: transparent !important;
    font-family: 'Segoe UI', sans-serif;
    font-size: 6px !important;                                           
  }

  @page {
    size: auto;
    margin: 15mm;
  }

  .footer{
    padding: 5px;
  }
  .signs{
    display: flex;
    justify-content: space-between;      
    padding-bottom: 50px;
  }
  .header{
    display: flex;
    flex-direction: row;
    justify-content: space-between; 
  }
  .logo img{
    width: 100px;
    margin-left: -8px;
  }
  .organization{
    padding-bottom: 40px;
    text-align: center;
  }
  .schoolName{
    font-size: 20px;
    font-weight: bold;
  }
  .foundation-name, .address{
    font-size: 10px;
    text-align: center;
  }
  .marathi-title, .english-title{
    font-size: 17px;
  }
  .udise{
    padding-bottom: 80px;
    font-weight: bold;
  }
  .header-title{
    text-align: center;
    margin-top: -38px;
  }
  .table td{
    font-size: 15.8px;
    text-decoration: solid;
    border: 1px solid black
  }
  .data{
    font-weight: bold;
  }
  .table{
    border: 1px solid black
  }
  .powered{
    font-size:11px;
    text-align: center;
  }

}
